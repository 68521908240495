<template>
    <div class="home">

      <div style="display:flex;position: fixed; 
    width: 100%;
    background-color: #fff;
    z-index: 999;">
        <el-menu default-active="/userexamine/articleviewedit" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="/userexamine/articleviewedit">内容审核</el-menu-item>
          <el-menu-item index="/userexamine/articleexaminelist">审核记录</el-menu-item>
        </el-menu>  
        <div style="
            height: 60px;
            line-height: 60px;
            right: 42px;
            position: fixed;"><i @click="handleSelect('/userexamine/articlereview')" class="el-icon-close"></i></div>
        </div>
      <div>
      <div class="pt20"></div>
      <div class="jiben">
      <div style="display: flex;" class="bgl"> <h4  style="margin: 0;width: 65px;line-height: 28px;">基本信息</h4>
      <el-button class="mj" @click="shouqi" v-show="btnshow" size="mini" type="info">展开</el-button>
      <el-button class="mj" @click="shouqi" v-show="!btnshow" size="mini" type="info">收起</el-button>
      </div>
      <div class="fle" style="display:flex;" v-show="!btnshow">
        <div>
              <div>提交时间：  <input type="text" disabled v-model="form.created_at"></div>
              <div>账户手机号：<input type="text" disabled v-model="form.phone"></div>
        </div>
        <div>
            <div>审核订单编号：<input type="text" style="width:220px" disabled v-model="form.no"></div>   
            <div v-if="form.is_authentication == 1">认证用户：<input style="width:50px" type="text" disabled value="是"></div>  
            <div v-if="form.is_authentication != 1">认证用户：<input style="width:50px" type="text" disabled value="否"></div> 
        </div>
        <div>
              <div v-if="form.status == 1">审核状态：<input type="text" disabled value="待审核"></div>
              <div v-if="form.status == 2">审核状态：<input type="text" disabled value="审核通过"></div>
              <div v-if="form.status == 3">审核状态：<input type="text" disabled value="审核未通过"></div>
              <div>用户编号：<input type="text" disabled v-model="form.article.user.uid"></div> 
        </div>
        <div>
              <div v-if="form.order_no">  是否与投诉订单联动：<input style="width:50px" type="text" disabled value="是"></div>
              <div v-if="!form.order_no"> 是否与投诉订单联动：<input style="width:50px" type="text" disabled value="否"></div>
              <div></div> 
        </div>
      </div>
        <div style="display:flex;" class="bgl">
          <h4 style="margin: 0;line-height: 28px;">待审核内容</h4>
          <el-button class="mj" @click="shouqi2" v-show="btnshow2" size="mini" type="info">展开</el-button>
          <el-button class="mj" @click="shouqi2" v-show="!btnshow2" size="mini" type="info">收起</el-button>
        </div>
        <div class="fle"  v-show="!btnshow2">
          <div style="margin-bottom:10px;">标题：<input type="text" :title="form.article.title" style="width:470px;" disabled v-model="form.article.title"></div>
          <div style="display: flex;">
            <div>话题：<input type="text" style="width:470px;"  :title="form.article.topic.name" disabled v-model="form.article.topic.name"></div>
            <div v-if="form.article.topic.status==1 || form.article.topic.status==3">用户自定义：<input style="width:30px" type="text" disabled value="否"></div>
            <div v-if="form.article.topic.status!=1 && form.article.topic.status!=3">用户自定义：<input style="width:30px" type="text" disabled value="是"></div>
            <!-- 2021.9.6.14:11 beak topic.status等于1或3就不是用户自定义 否则是  1启用 2删除 3禁用 -->
            <!-- 9.13 13.52 beak 待审核状态下
  话题不是用户自定义（status==1||3）审核结果直接展示 展示根据 话题状态来 启用是通过 其他是未通过
  话题是用户自定义则点选
  非待审核状态下 原逻辑
  根据topic_examine这个字段有值，则根据topic_examine.status审核结果直接展示 展示根据 话题状态来 启用是通过 其他是未通过
  没值则点选 -->
  
            <div v-if="form.status == 1 && (form.article.topic.status==1 || form.article.topic.status == 3)" style="display:flex;"><div style="color:red;padding: 0;">审核结果：</div>{{form.article.topic.status == 1 ? '通过' : '未通过'}}</div>
            <div v-if="form.status != 1 && form.article.topic.topic_examine" style="display:flex;"><div style="color:red;padding: 0;">审核结果：</div>{{form.article.topic.topic_examine.status==1?'通过':'未通过'}}</div>
            <div v-if="(form.status != 1 && !form.article.topic.topic_examine) || (form.status == 1 && (form.article.topic.status!=1 && form.article.topic.status != 3))" style="display:flex;"><div style="color:red;padding: 0;">审核结果：</div>
              <div>
                <el-radio v-model="topic_examinestatus" :label="1">通过</el-radio>
                <el-radio v-model="topic_examinestatus" :label="0">不通过</el-radio>
              </div>
            </div>
            <!-- -->
          </div>

          <div style="display: flex;">
            <div>定位：<input type="text" style="width:470px;" :title="form.article.location"  disabled v-model="form.article.location"></div> 
            
            <div v-if='form.article.location' style="display: flex;">
              <div v-if="!is_location_zdy">用户自定义：<input style="width:30px" type="text" disabled value="否"></div>
              <div v-else>用户自定义：<input style="width:30px" type="text" disabled value="是"></div>
              <!--有经纬度就不是自定义 定位审核不是用户自定义的，审核结果一定是通过 -->
              
              <div v-if="!is_location_zdy" style="display:flex;"><div style="color:red;padding: 0;">审核结果：</div>通过</div>
              <!-- 用户自定义且有审核结果 -->
              <div v-if="is_location_zdy && form.article.location_examine" style="display:flex;"><div style="color:red;padding: 0;">审核结果：</div>{{form.article.location_examine?form.article.location_examine.status==1?'通过':form.article.location_examine.status==0?'未通过':'':''}}</div>
              <!-- 用户自定义且没有审核结果 -->
              <div v-if="is_location_zdy && !form.article.location_examine" style="display:flex;"><div style="color:red;padding: 0;">审核结果：</div>
                <div> 
                <el-radio v-model="location_examinestatus" :label="1">通过</el-radio>
                <el-radio v-model="location_examinestatus" :label="0">不通过</el-radio>
                </div>
              </div>
            </div>

          </div>

        </div>
          <div class="fle">
            <div>图片：</div>
            <div>
              <el-image v-for="images in form.article.images" :key="images.id"
              style="width: 100px; height: 100px;padding: 0;margin-right: 12px;"
              :src="images.images_url" 
              :preview-src-list="imgsrcall">
            </el-image>
            </div>
            <div>正文：</div>
            <div><textarea type="text" style="width:500px;min-height:100px;" disabled v-model="form.article.content"></textarea></div>
          </div>
      </div>
      
    <div class="form">
      <h4 class="bgl" style="text-align: left;">审核处理信息</h4>
      <el-form ref="form" :model="form" >
        <el-form-item :required="true"  label="审核结果">
          <!-- 1.查看进来的不能选 2.未审核通过不能选 3.话题或者定位不通过不能选 -->
          <el-select 
             v-model="form.status2" :disabled='look || form.status == 3 ||butongguo' @change="refreshStatus" placeholder="请选择">
            <el-option
              v-for="item in opstatus"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item :required="true" v-if="form.status2 == 3" label="违规程度">
          <el-select :disabled='look' @change="refreshStatus2" v-model="form.violation2" placeholder="请选择">
            <el-option
              v-for="item in opviolation"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :required="true" v-if="form.status2 == 3" label="未通过的原因">
          <div v-if="form.status != 3">
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox><br>
            <el-checkbox v-if="dw" checked disabled >定位违规</el-checkbox>
            <el-checkbox v-if="ht" checked disabled >话题违规</el-checkbox>
            
            <el-checkbox-group style="width: 580px;" v-model="checkedCities" @change="handleCheckedCitiesChange"> 
              <el-checkbox  v-for="city in cities" :key="city.id" :label="city" :disabled='city.disabled' >{{city.name}}</el-checkbox>
            </el-checkbox-group>
              <el-input style="width: 400px;" :disabled='zdy' maxlength="12" v-model="zidingyi" placeholder="请输入12字以内说明"></el-input>
          </div>
          <div v-if="form.status == 3">
           <el-checkbox checked disabled v-for="reason in form.reason" :key="reason.id" :label="reason" >{{reason.name}}</el-checkbox>
          </div>
        </el-form-item>

        <el-form-item style="margin-bottom:0px;    display: flex;" :required="false" label="备注">
          <el-input
          style="width:600px;"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10}"
            placeholder="请输入内容"
            v-model="form.remark">
          </el-input>
        </el-form-item>
        <!--9.7文档第五条
        【内容审核详情页】进行修改：1. 当前状态为“待审核”时，
        若“审核结果”中选择了“审核不通过”时，将“运营操作信息”整个模块在页面上做隐藏；
        当“审核结果”中选择为“审核通过”，或未选择时， “运营操作信息”整个模块在页面上正常显示
        言简意赅：待审核+不通过 隐藏 form.status == 1（待审核） form.status2==3(审核结果不通过)
        -->
        <!--9.29文档第四条
        待审核和审核通过都显示 但不能编辑 -->
        <div v-if="articleartedit &&  form.status2 != 3 && form.status != 3"   class="lefxx">
          <div style="display:flex;">
            <h4 >运营操作信息</h4>
            <div style="
            
    margin-top: auto;
    margin-bottom: auto;">
<el-button style="
    height: 24px;
    padding: 4px 10px;
    font-size: 12px;
    margin-left: 10px;" type="primary" v-if="!look && form.status == 1" @click="onSubmit2">绑定</el-button>
            </div>
            <!-- 只有待审核状态和非查看状态才能够绑定 -->
          </div>
            <div style="display:flex;line-height: 30px;">
              <!-- form.status == 3上一次的审核结果不通过不能改 --><!-- 9.2文档+ 审核结果不通过且没勾选不能改 -->
               <el-checkbox :disabled="form.status == 3 || (form.status2==3 && !checkeds)"  v-model="checkeds">评为精选内容</el-checkbox>
                <div class="flex" style="margin-left: 50px;" >内容标签：
                   <div  v-for="label in form.article.label" :key="label.label_id">{{label.label?label.label.name:''}}{{form.article.label.length > 1 && form.article.label[form.article.label.length-1]!=label?'/':''}}</div>
                   <el-button v-if="form.status == 1" class="ww" type="primary" @click="goedit(form.article.label)" size="mini" icon="el-icon-edit"></el-button>
                </div>
            </div>
        </div>

      <el-form-item style="margin-top: 10px;">
        <el-button type="primary" v-if="!look && form.status != 3" @click="onSubmit" style="margin-left: 45%;" >提交</el-button>
      </el-form-item>
    </el-form>
</div>
</div>
</div>
</template>

<script>
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
      articleartedit:false,
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
      ],
      is_location_zdy:false,//定位是否是用户自定义
      is_topic_zdy:'',//话题是否是用户自定义
      zdy:true,
      ht:false,
      dw:false,
      look:false,
      checkAll: false,
      checkedCities: [],
      imgsrcall:[],
      cities: [],
      isIndeterminate: false,
      drawer: false,
      id:0,
      checkeds:false,
      topic_examinestatus:2,
      location_examinestatus:2,
      islabal:[],
      form: {
        violation2:'',
        order_no:'',
        status:'',
        status2:'',
        article:{
            content:'',
            id:'',
            images:[{images_url:''}],
            is_recommend:0,
            label:[],
            location_examine:'',
            title:'',
            topic_id:'',
            topic:{
              id:'',
              topic_examine:'',
              name:'',
            },
            user:{
              uid:''
            }
          }
        },
        options:[],
        opstatus:[
          {
            value: 2,
            label: '审核通过'
          },
          {
            value: 3,
            label: '审核未通过'
          }
        ],
        opviolation:[
          {
            value: '1',
            label: '轻度'
          },
          {
            value: '2',
            label: '重度'
          },
          {
            value: '3',
            label: '删除'
          }
        ],
        zidingyi:'',
        btnshow:false,
        btnshow2:false,
        butongguo:false,
        articleExamineing:true,//防抖
    }
  },
  watch:{
    topic_examinestatus(a){
      if(a==0){
        this.form.status2 = 3;
        this.butongguo = true;
        this.ht=true;
        
      }else if(a==1 && (this.location_examinestatus == 1 || this.location_examinestatus == 2)){
        this.butongguo = false;
        this.form.status2 = '';
        this.ht=false;
       
      }else{
        if(this.location_examinestatus == undefined){ return }//回显来了以后可能出现
        this.butongguo = true;
        this.form.status2 = 3;
        this.ht=false;

      }
      if(this.form.violation2 != ''){
        this.refreshStatus2(this.form.violation2);
      }
      
    },location_examinestatus(a){
      if(a==0){
        this.form.status2 = 3;
        this.butongguo = true;//控制审核
        this.dw = true;
    
      }else if(a==1 && (this.topic_examinestatus == 1 || this.topic_examinestatus == 2)){//a == 1是通过
        this.butongguo = false;
        this.form.status2 = '';
        this.dw = false;
       
      }else{
        if(this.topic_examinestatus == undefined){ return }//回显来了以后可能出现
        this.butongguo = true;
        this.form.status2 = 3;
        this.dw = false;
        
      }
      if(this.form.violation2 != ''){
        this.refreshStatus2(this.form.violation2);
      }
    }
  },
  computed:{
  },
  methods: {
      refreshStatus(){
        this.$forceUpdate()
      },
      refreshStatus2(val){
        if(val == 1 || val == 2){
          this.cities = [{id:1,name:'含有敏感词'},{id:3,name:'包含广告'},{id:5,name:'图片违规'},{id:7,name:'标题违规'},{id:9,name:'正文违规'}];
          switch(this.location_examinestatus){//0不 1通过 2默认
            case 0:
              break;
            case 1:
             this.cities.push({id:8,name:'定位违规',disabled:true});
              break;
            case 2:
             this.cities.push({id:8,name:'定位违规'});
              break;
          }
          switch(this.topic_examinestatus){//0不 1通过 2默认
            case 0:
              break;
            case 1:
             this.cities.push({id:6,name:'话题违规',disabled:true});
              break;
            case 2:
             this.cities.push({id:6,name:'话题违规'});
              break;
          }
          this.cities.push({id:4,name:'自定义'});//为了把自定义排到最后
       }else if(val == 3){
          this.cities = [{id:10,name:'分类与话题不符'},{id:11,name:'分类与内容不符'},{id:12,name:'图片与内容无关'}];
          
          switch(this.topic_examinestatus){//0不 1通过 2默认
            case 0:
              break;
            case 1:
             this.cities.push({id:6,name:'话题违规',disabled:true});
              break;
            case 2:
             this.cities.push({id:6,name:'话题违规'});
              break;
          }
          
          this.cities.push({id:4,name:'自定义'});//为了把自定义排到最后
        }
        this.$forceUpdate()
      },
      handleSelect(url){
        let yutian = {
          form:this.form,
          cities:this.cities,
          checkAll:this.checkAll,
          checkedCities:this.checkedCities,
          zidingyi:this.zidingyi,
          checkeds:this.checkeds,
          topic_examinestatus:this.topic_examinestatus,
          location_examinestatus:this.location_examinestatus,
        }
        sessionStorage.setItem("yutian", JSON.stringify(yutian));
        this.$router.push(url)
      },
      handleCheckAllChange(val) {
      // let arr = [];
      // if(this.form.violation2 == 1 || this.form.violation2 == 2){
      //   arr = [{id:1,name:'含有敏感词'},{id:3,name:'包含广告'},{id:5,name:'图片违规'},{id:7,name:'标题违规'},{id:9,name:'正文违规'},{id:4,name:'自定义'}];
      // }else{
      //   arr = [{id:10,name:'分类与话题不符'},{id:11,name:'分类与内容不符'},{id:12,name:'图片与内容无关'},{id:4,name:'自定义'}];
      // }
        this.zdy = !val;
        this.checkedCities = val ? this.cities : [];
        this.isIndeterminate = false;
        // console.log(this.checkedCities);
        // for(let i in this.checkedCities){
        //   if(this.checkedCities[i].disabled){
        //       this.checkedCities[i].checked = true;
        //   }
        // }
      },
      handleCheckedCitiesChange(value) {
        let fid = true;
        for(let i in value){
          if(value[i].id == 4){
            this.zdy = false;
            fid = false;
          }
        }
        if(fid){this.zdy = true}
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
   //   this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },
     onSubmit2() {
      let _this = this;
      let postdata = this.form;
      let postdataid = [];
      for(let i in postdata.article.label){
          postdataid.push(postdata.article.label[i].label_id);
      }
      if(postdataid.length == 0){
          _this.$message.error('请添加内容标签');
          return
      }
      let obj = {
          id:postdata.article.id,
          updated_at:postdata.article.updated_at?postdata.article.updated_at:'',
          is_recommend:_this.checkeds?1:0,
          label:postdataid,
      }
      axios.post(config.article,obj)
        .then(function (response) {
            if(response.data.code == 200){
              _this.islabal = postdata.article.label;
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    },
     onSubmit() {
      let _this = this;
      let postdata = {};
      this.form.id = this.id;
      let cities = this.checkedCities;
        for(let i in cities){
          if(cities[i].name == '自定义'){
            if(_this.zidingyi == ''){
          _this.$message.error('自定义内容必填！'); 
              return;
            }
            cities[i].name = _this.zidingyi;
          }
        }
      if(this.dw){cities.push({id:8,name:'定位违规'})}//如果定位违规把定位加进去
      if(this.ht){cities.push({id:6,name:'话题违规'})}//如果话题违规把定位加进去
        
      //_this.islabal 对标数据库的labal _this.islabal有数据则绑定了 标签
      //小米 9-6 10.55: 绑定，提交时检验这篇内容是否已绑定1个以上标签，没绑定时不允许提交，提示弹窗：未绑定内容标签
      if(_this.articleartedit && !_this.islabal.length && _this.form.status2 != 3){//当有审核+运营权限时，“内容标签”设为必填项，且必须要绑定
      //当将审核结果提交为审核不通过时，不需要绑定标签；当将审核结果提交为审核通过时，需要绑定最少1个标签 2021-9-8新加
          _this.$message.error('内容标签为必填项，且必须要绑定'); 
          return;
      }
      
      if(!_this.form.status2){//审核结果必填
            _this.$message.error('审核结果必选'); 
            return;
      }
      
      if(_this.form.status2 == 3){//未通过
        // if(_this.checkeds){//未通过的情况下不允许为评为精选内容
        //   _this.$message.error('提交失败，请取消“评为精选内容”');
        //     return;
        // }

        if(!_this.form.violation2){
          _this.$message.error('违规程度必选');
            return;
        }
        postdata.violation = _this.form.violation2;//违规程度
        if(cities.length == 0){
          _this.$message.error('违规原因必选');
            return;
        }
        postdata.reason = JSON.stringify(cities);//违规原因
      }else{
        postdata.reason = '[]';
        postdata.violation = 0;
      }
        if(_this.form.remark){
          postdata.remark = _this.form.remark;
        }else{
          postdata.remark = '';
        }

        postdata.status = _this.form.status2;
        postdata.id = _this.form.id;
        postdata.updated_at = _this.form.updated_at;

        if(_this.form.article.topic.topic_examine){
          postdata.topic_status = _this.form.article.topic.topic_examine.status;
        }else{
          let flag = (_this.form.status != 1 && !_this.form.article.topic.topic_examine) || (_this.form.status == 1 && (_this.form.article.topic.status!=1 && _this.form.article.topic.status != 3));
          if(_this.topic_examinestatus == 2 && flag ){//能选话题却没有结果
            _this.$message.error('话题审核结果必选');
            return;
          }else{
            if(_this.form.status == 1 && (_this.form.article.topic.status==1 || _this.form.article.topic.status == 3)){
              //{{form.article.topic.status == 1 ? '通过' : '未通过'}}
              postdata.topic_status = _this.form.article.topic.status;
            }else if(_this.form.status != 1 && _this.form.article.topic.topic_examine){
              //{{form.article.topic.topic_examine.status==1?'通过':'未通过'}}
              postdata.topic_status = _this.form.article.topic.topic_examine.status;
            }else{
              postdata.topic_status = _this.topic_examinestatus== undefined?2:_this.topic_examinestatus;//选择的结果
            }
          } 
        } 
        if(_this.location_examinestatus == undefined){
            _this.location_examinestatus = 2;
        }
    // console.log(_this.is_location_zdy) 
    // console.log(_this.location_examinestatus)
    //_this.is_location_zdy==true 用户自定义 _this.location_examinestatus == 2 审核结果没选
        if(_this.is_location_zdy && _this.location_examinestatus == 2){//用户自定义且没有选审核结果
          //且该定位曾经没有审核记录
          if(!_this.form.article.location_examine){
            _this.$message.error('定位审核结果必选');
            return;
          }
        }

        if(_this.form.article.location_examine){//判断有没有选择结果
          postdata.location_status = _this.form.article.location_examine.status;
        }else{
           postdata.location_status = _this.location_examinestatus;
        }

       

        if(_this.articleExamineing){//防抖
          _this.articleExamineing = false;
          
            axios.post(config.articleExamine,postdata)
              .then(function (response) {
                  if(response.data.code == 200){
                    _this.$message({
                      message: response.data.message,
                      type: 'success'
                    });
                    _this.goNav('/userexamine/articlereview');
                  }else{
                      _this.$message.error(response.data.message);
                  }
                  _this.articleExamineing = true;//防抖
                })
              .catch(function (error) {
                console.log(error);
              });   
          }

      },
      goNav(url){
        this.$router.push(url)
      },
      
      goedit(obj){
        let arr = [];
        for(let i in obj){
            arr.push({
                id:obj[i].label.id,
                name:obj[i].label.name,
            })
        }
        let yutian = {
          form:this.form,
          cities:this.cities,
          checkAll:this.checkAll,
          checkedCities:this.checkedCities,
          zidingyi:this.zidingyi,
          checkeds:this.checkeds,
          topic_examinestatus:this.topic_examinestatus,
          location_examinestatus:this.location_examinestatus,
        }
        sessionStorage.setItem("yutian", JSON.stringify(yutian));
        sessionStorage.setItem("artedit", JSON.stringify(arr));
        sessionStorage.setItem("gpc", 1);
        sessionStorage.setItem("art", 1);
        this.$router.push('/article/editarticlelabel');
      },
      shouqi(){//展开收起
        this.btnshow = !this.btnshow;
      },
      shouqi2(){//展开收起
        this.btnshow2 = !this.btnshow2;
      },
      getjur(){
        let _this = this;
        axios.get(config.jur)
        .then(function (response) {
            if(response.data.code == 200){
              let list = response.data.data;
              zsgc(list);//内容编辑的权限
            }
          })
        .catch(function (error) {
          console.log(error);
        });
        function zsgc(list){
          for(let i in list){
            if(list[i]._child){
              if(list[i].url == '/article/list'){//传入运营下内容列表的路由
                add(list[i]._child)
              }else{
                zsgc(list[i]._child);
              }
            }
          }
        }
        function add(list){
          for(let i in list){
            if(list[i].url == 'edit'){//判断有无运营内容列表的路由下有没有编辑权限，如果有则展示
              _this.articleartedit = true;
            }
          }
        }
      },
    init(a,b,yutian){
        let _this = this;
        let obj = JSON.parse(a);
        this.id = obj.id;
        this.look = obj.look;//查看过来
        let objb = b == '' ? '' : JSON.parse(b);
        let huixian = yutian == '' ? '' : JSON.parse(yutian);
        axios.get(config.articleExamineInfo,{params:{id:obj.id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.form.id = obj.id;
                _this.form.status2 = _this.form.status == 1 ? '' : _this.form.status;//审核状态赋值
                _this.form.violation2 = _this.form.violation ? _this.form.violation+'':'';//违规程度赋值
                _this.islabal = _this.form.article.label;
//is_topic_zdy
//有经纬度就不是自定义，没有经纬度 却有数据就是自定义
                if(_this.form.article.lat || _this.form.article.lng){//如果有经纬度 一定不是自定义
                    _this.is_location_zdy = false;//我自己生成一个字段控制是否是用户自定义
                }else{
                  if(_this.form.article.location){//没有经纬度 却有数据就是自定义
                      _this.is_location_zdy = true;
                  }
                }
                
              

                if(_this.form.article.topic && _this.form.article.topic.topic_examine){
                  if(_this.form.article.topic.topic_examine.status==0){//如果话题不通过
                      _this.butongguo = true;
                      _this.form.status2 = 3;
                      _this.ht = true;
                  }
                }

                if(_this.form.article.location_examine){
                  if(_this.form.article.location_examine.status==0){//如果定位不通过
                      _this.butongguo = true;
                      _this.form.status2 = 3;
                      _this.dw = true;
                  }
                }

                _this.checkeds = _this.form.article.is_recommend?true:false;//是否为精选内容

                if(_this.form.article.images){//图片预览 格式过滤
                  for(let i in _this.form.article.images){
                    _this.imgsrcall.push(_this.form.article.images[i].images_url);
                  }
                }
                
                if(huixian){
                  _this.topic_examinestatus = huixian.topic_examinestatus,
                  _this.location_examinestatus = huixian.location_examinestatus,
                  setTimeout(function(){//延迟一下因为 话题和定位的点选会触发联动
                    _this.form = huixian.form
                    _this.cities = huixian.cities,
                    _this.checkAll = huixian.checkAll,
                    _this.zidingyi = huixian.zidingyi,
                    _this.checkeds = huixian.checkeds

                     for(let i in huixian.checkedCities){
                      for(let j in _this.cities){
                        if(huixian.checkedCities[i].id == _this.cities[j].id){
                          _this.checkedCities.push(_this.cities[j]); 
                          if(huixian.checkedCities[i].id == 4){
                            _this.zdy = false;
                          }
                        }
                      }
                    }
                    
                    if(objb != 1){//运营操作标签回显  1就是从列表点编辑或者是查看过来的 不处理
                        _this.form.article.label = [];
                        for(let i in objb){
                        _this.form.article.label.push({
                                label_id:objb[i].id,
                                label:{id:objb[i].id,name:objb[i].name}
                            })
                        }
                    }
                  },100)
                }
                
            }else{
                _this.$message.error(response.data.message);
                _this.$router.push('/userexamine/articlereview');
            }
                
          })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created(){
      let a = sessionStorage.getItem('edit');//编辑数据
      let b = sessionStorage.getItem('artedit');//运营操作标签
      let yutian = sessionStorage.getItem('yutian');//运营操作标签
      this.init(a,b,yutian);
      this.getjur();
  }
}
</script>
<style scoped>

.jiben{
    text-align: left;   margin-top: 45px; 
}
.flex{
  display: flex;
}
.le{
  margin-left: 20px;
}
.mj{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}
.ww{
  margin-left: 20px;
}
.lefxx{
  text-align: left;
}
.el-menu--horizontal > .el-menu-item.is-active{
    font-weight: bold;
}
.el-icon-close:before{
  font-size: 24px;
}
.el-menu-item{
  font-size: 16px;
}
.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 18px 10px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.fle div input{
  height: 20px;
}
.fle div div input{
  height: 20px;
}
.el-image-viewer__next{
  right:280px;
}
.el-image-viewer__prev{
  left: 280px;
}
.bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
}
</style>
